import {
    Admin,
    Create,
    Datagrid,
    DateField,
    Edit,
    ImageField,
    ImageInput,
    List,
    required,
    Resource,
    SimpleForm,
    TextField,
    TextInput,
    UrlField,
    FileInput,
    FileField,
} from 'react-admin';
import { CMSLayout, FileInputPlaceholder } from '../../../../components/CMSUtils';
import { dataProvider } from '../../../../utils/cmsDataProvider';

const WallGraphicsList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="collage_marker_id" />
            <ImageField source="url" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export const WallGraphicEdit = () => (
    <Edit transform={(data) => ({ ...data, isMedia: true })}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="title" validate={[required()]} />
            <TextInput source="url" validate={[required()]} />
            <TextInput source="thumbnail_url" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const WallGraphicCreate = () => (
    <Create transform={(data) => ({ ...data, isMedia: true })}>
        <SimpleForm>
            <TextInput source="title" validate={[required()]} />
            <TextInput source="collage_marker_id" label="User Token ID" validate={[required()]} />
            <FileInput
                source="file"
                accept={['image/jpeg', 'image/png']}
                validate={[required()]}
                placeholder={<FileInputPlaceholder />}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
);

export const CMS = () => (
    <Admin
        dataProvider={dataProvider}
        basename='/admin/media/wall-graphics'
        layout={CMSLayout}
    >
        <Resource
            name="wall-graphics"
            recordRepresentation="name"
            options={{ label: 'Wall Graphics' }}
            list={WallGraphicsList}
            edit={WallGraphicEdit}
            create={WallGraphicCreate}
        />
    </Admin>
);
