import {
    Admin,
    Create,
    Datagrid,
    DateField,
    Edit,
    ImageField,
    ImageInput,
    List,
    required,
    Resource,
    SimpleForm,
    TextField,
    TextInput,
    UrlField,
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import { FileInputPlaceholder } from '../../components/CMSUtils.jsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { dataProvider } from '../../utils/cmsDataProvider.js';

export const WallGraphicContentList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
            <ImageField source="url" label="preview" />
            <UrlField source="url" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export const WallGraphicCreate = ({ userId }) => (
    <Create>
        <SimpleForm>
            <TextInput source="title" validate={[required()]} />
            <ImageInput source="url" label="Wall Graphic" accept="image/*">
                <ImageField source="url" />
            </ImageInput>
            <TextInput style={{ display: 'none' }} hidden source="collage_marker_id" defaultValue={userId} validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const WallGraphicEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="title" />
            <ImageInput source="url" label="Wall Graphic" accept="image/*">
                <ImageField source="url" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

// export const CMS = () => {
//     const { userId } = useParams();

//     return (
//         <Admin
//             dataProvider={dataProvider}
//             requireAuth={false}
//             basename={`/wall-graphics/user/${userId}/`}
//         >
//             <Resource
//                 name={`wall-graphics/userCollageContent/${userId}`}
//                 list={WallGraphicContentList}
//                 edit={WallGraphicEdit}
//                 create={WallGraphicCreate({ userId })}
//             />
//         </Admin>
//     );
// };




export const CMS = () => {

    const { userId: userId2 } = useParams();


    const [searchParams, setSearchParams] = useSearchParams();
    const [userId, setUserId] = useState('');

    console.log('userId: ', userId);

    useEffect(() => {
        setUserId(userId2);
    }, []);

    if (!userId) {
        return (<div>No user id provided</div>);
    }

      
    return (
        <>
        {
            userId !== 'null' ? (
                <Admin
                    dataProvider={dataProvider}
                    basename={`/userWallGraphics/${userId}`}
                >
                    <Resource
                        name={`images/userWallGraphics/${userId}`}
                        list={WallGraphicContentList}
                        edit={WallGraphicEdit}
                        create={WallGraphicCreate({ userId })}
                    />
                </Admin>
            ) : (
                <div>Please edit general media content through the admin portal.</div>
            )
        }
        </>
    );
};