import {
  getProductLaminates,
  getProductOptions,
  getProductPaintAndTrim,
  getLaminateSwatchUrl,
  getPaintedTrimSwatchUrl,
  getProductOptionsAndMaterials, getProductMaterialByType, getMaterialSwatchUrl,
} from '../utils/selectors.js'
import { Link } from "./Link";

import { imageNotFoundUrl } from '../utils/index.js';
import { getDisplayName } from '../utils/metaData.js';

const noneString = 'None Selected'

export const TwoColumnProductDetailsOptionsGrid = ({ className, product }) => {

  return (
    <div className={`grid grid-cols-2 gap-x-[60px] gap-y-[30px] mt-[16px] text-left justify-items-center ${className}`}>

        {/*{*/}
        {/*  getProductMaterialByType(product, "glass").length ? (*/}
        {/*      <div className=" font-medium">*/}
        {/*        <div className="text-[18px] font-semibold mb-[20px]">Glass</div>*/}
        {/*        <ul>*/}
        {/*          {*/}
        {/*            getProductMaterialByType(product, "glass").length ? getProductMaterialByType(product, "glass").map((val, idx) => (*/}
        {/*                <li id={idx} className="flex flex-row swatch-li">*/}
        {/*                  <img src={getMaterialSwatchUrl(val, "glass")} className="swatch w-[30px] h-[30px]"/>*/}
        {/*                  <div className="my-auto">{getDisplayName(val)}</div>*/}
        {/*                </li>*/}
        {/*            )) : <div>{noneString}</div>*/}
        {/*          }*/}
        {/*        </ul>*/}

        {/*      </div>*/}
        {/*  ) : ""*/}
        {/*}*/}


          {
            // getProductLaminates(product).length ? (
              <div className=" font-medium">
                <div className="text-[18px] font-semibold mb-[20px]">Laminates</div>
                <ul>
                  {
                    getProductLaminates(product).length ? getProductLaminates(product).map((val, idx) => (
                      <li id={idx} className="flex flex-row swatch-li">
                        <img src={getLaminateSwatchUrl(val)} className="swatch w-[30px] h-[30px]"/>
                        <div className="my-auto">{getDisplayName(val)}</div>
                      </li>
                    )) : <div>{noneString}</div>
                  }
                </ul>

              </div>
          }
          {
            // getProductPaintAndTrim(product).length ? (
              <div className="font-medium">
                <div className="text-[18px] font-semibold mb-[20px]">Paint & Trim</div>
                <ul>
                  {
                    getProductPaintAndTrim(product).length ? getProductPaintAndTrim(product).map((val, idx) => (
                      <li id={idx} className="flex flex-row swatch-li">
                        <img src={getPaintedTrimSwatchUrl(val)} className="swatch w-[30px] h-[30px]"/>
                        <div className="my-auto">{getDisplayName(val)}</div>
                      </li>
                    )) : <div>{noneString}</div>
                  }
                </ul>
              </div>
            // )
          }
          {
            // getProductOptions(product).length ? (
              <div className="font-medium">
                <div className="text-[18px] font-semibold mb-[20px]">Options</div>
                <ul className="mb-[20px]">
                  {
                    getProductOptions(product).length ? getProductOptions(product).map((val, idx) => (
                      <li id={idx} className="flex flex-row">
                        <div className="my-auto">- {getDisplayName(val)}</div>
                      </li>
                    )) : <div>{noneString}</div>
                  }
                </ul>
              </div>
            // ) : <div>{noneString}</div>
          }
          {/* <Link href="#" alignment="LEFT" className="text-[18px] text-[#191919]">
            <div>LEARN MORE</div>
          </Link> */}
        </div>
  )
}

export const ThreeColumnProductDetailsOptionsGrid = ({ className, product }) => {

  return (
    <div className={`grid grid-cols-3 mt-[40px] gap-x-[15px] ${className}`}>
      {/*{*/}
      {/*  getProductMaterialByType(product, "glass").length ? (*/}
      {/*  <div className=" font-medium">*/}
      {/*    <div className="text-[18px] font-semibold mb-[20px]">Glass</div>*/}
      {/*    <ul>*/}
      {/*      {*/}
      {/*        getProductMaterialByType(product, "glass").length ? getProductMaterialByType(product, "glass").map((val, idx) => (*/}
      {/*            <li id={idx} className="flex flex-row swatch-li">*/}
      {/*              <img src={getMaterialSwatchUrl(val, "glass")} className="swatch w-[40px] h-[40px]"/>*/}
      {/*              <div className="my-auto">{getDisplayName(val)}</div>*/}
      {/*            </li>*/}
      {/*        )) : <div>{noneString}</div>*/}
      {/*      }*/}
      {/*    </ul>*/}

      {/*  </div>*/}
      {/*  ) : ""*/}
      {/*}*/}
      {
        // getProductLaminates(product).length ? (
          <div className=" font-medium">
            <div className="text-[18px] font-semibold mb-[20px]">Laminates</div>
            <ul>
              {
                getProductLaminates(product).length ? getProductLaminates(product).map((val, idx) => (
                  <li id={idx} className="flex flex-row swatch-li">
                    <img src={getLaminateSwatchUrl(val)} className="swatch w-[40px] h-[40px]"/>
                    <div className="my-auto">{getDisplayName(val)}</div>
                  </li>
                )) : <div>{noneString}</div>
              }
            </ul>

          </div>
        // )
      }
      {
        // getProductPaintAndTrim(product).length ? (
          <div className="font-medium">
            <div className="text-[18px] font-semibold mb-[20px]">Paint & Trim</div>
            <ul>
              {
                getProductPaintAndTrim(product).length ? getProductPaintAndTrim(product).map((val, idx) => (
                  <li id={idx} className="flex flex-row swatch-li">
                    <img src={getPaintedTrimSwatchUrl(val)} className="swatch w-[40px] h-[40px]"/>
                    <div className="my-auto">{getDisplayName(val)}</div>
                  </li>
                )) : <div>{noneString}</div>
              }
            </ul>
          </div>
        // ) : <div>{noneString}</div>
      }
      {
        // getProductOptions(product).length ? (
          <div className="font-medium">
            <div className="text-[18px] font-semibold mb-[20px]">Options</div>
            <ul className="mb-[20px]">
              {
                getProductOptions(product).length ? getProductOptions(product).map((val, idx) => (
                  <li id={idx} className="flex flex-row">
                    <div className="my-auto">- {getDisplayName(val)}</div>
                  </li>
                )) : <div>{noneString}</div>
              }
            </ul>
            {/* <Link href="#" alignment="LEFT" className="text-[20px] text-[#191919] mt-[20px]">
              <div>LEARN MORE</div>
            </Link> */}
          </div>
        // ) : <div>{noneString}</div>
      }
    </div>
  )
}


export const MobileProductDetails = ({ className, product, assemblyProductScreenshot, isAssemblyProduct }) => {
  return (
    <div id="product-section-mobile" className={`${className} flex-col`}>
      {
        isAssemblyProduct ? <img src={assemblyProductScreenshot.url} className="w-[100%]" /> : null
      }
      {
        product ? (
          <div className="flex flex-col">
            <div className="text-[24px] font-medium mt-[17px]">
              {getDisplayName(product.product)}
            </div>
            {/* {product.basePriceUSD > 0 ? (
              <div className="text-[18px] mt-[10px] mb-[10px] font-bold">Starting at: ${product.basePriceUSD}</div>
            ) : null} */}
           <TwoColumnProductDetailsOptionsGrid product={product} />
          </div>
        ) : (<div className="mt-[20px] xl:hidden">No furniture product configured yet for this room</div>)
      }
    </div>
  )
}
