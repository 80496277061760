import {
  Layout,
} from 'react-admin';

const appBar = () => null;

export const CMSLayout = (props) => <Layout {...props} appBar={appBar} />;

export const FileInputPlaceholder = () => (
  <div>
      <div>Drop a file to upload, or click here to browse.</div>
      {/* <div>Accepted image file formats: .jpg</div>
      <div>Accepted video file formats: .mp4</div> */}
  </div>
)