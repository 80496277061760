import highlight1 from "../assets/service_highlights_1.jpg";
import highlight2 from "../assets/service_highlights_2.png";
import highlight3 from "../assets/service_highlights_3.jpg";
import highlight4 from "../assets/service_highlights_4.jpg";

export const ServiceHighlights = () => {
  return (
    <section id="service-highlights">
      <div className="relative left-[50%] right-[50%] -ml-[50vw] -mr-[50vw] w-[100vw] bg-[#A39E97]">
        <div className="max-w-[1400px] mx-auto px-4 py-6 text-center">
          <h2 className="text-[32px] text-white font-light leading-tight mb-1">Learn About Our Product Categories</h2>
          <p className="text-white text-[17px] font-light tracking-wide">Bringing Wellness and Inspiration to WorkLIfe</p>
        </div>
      </div>
      <div className="table clear-both w-full pt-0 max-w-[1600px] mx-auto">
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <img src={highlight1} className="object-cover"/>
        </div>
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <div className="flex">
            <div className="text w-full sm:w-[60%] mx-auto text-left my-auto">
              <div className="h-full my-auto  px-4">
                <div className="text-3xl highlight-header">Modular Walls</div>
                <br/>
                <p className="text-sm leading-6 highlight-subtext">
                  Sophisticated, pre-assembled walls are engineered with functional simplicity and adaptability to move with changing workspaces.
                </p>
                {/* <a href="#" className="text-[#191919]">LEARN MORE</a> */}
                <div className="h-2"/>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="table clear-both w-full pt-0 max-w-[1600px] mx-auto">
        <div className="panel sm:float-left table-header-group sm:flex w-full sm:w-1/2 items-center">
          <div className="flex">
            <div className="text w-full sm:w-[60%] mx-auto text-left my-auto">
              <div className="h-full my-auto px-4">
              <div className="text-3xl highlight-header">Contract Interiors</div>
              <br/>
                <p className="text-sm leading-6 highlight-subtext">
                Make the most of your workspace with our flexible line of monitor arms, adaptable power source solutions, dynamic lihghting, keyboard managers, risers, foot & back suport, Bring comfort, organization and productivity to your workspace..
                </p>
                {/* <a href="#" className="text-[#191919]">LEARN MORE</a> */}
              <div className="h-2"/>
            </div>
          </div>
        </div>
        </div>
        <div className="panel sm:float-left table-caption w-full sm:w-1/2 sm:flex items-center">
          <img src={highlight3} className="object-cover"/>
        </div>
      </div>


      <div className="table clear-both w-full pt-0 max-w-[1600px] mx-auto">
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <img src={highlight4} className="object-cover"/>
        </div>
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <div className="flex">
            <div className="text w-full sm:w-[60%] mx-auto text-left my-auto">
              <div className="h-full my-auto  px-4">
                <div className="text-3xl highlight-header">Air Quality Management</div>
                <br/>
                <p className="text-sm leading-6 highlight-subtext">
                The latest IAQ breakthrough solution, Fellowes Array™, improves indoor air quality faster and more efficiently than ever before. Array is a scalable system of networked air purifiers designed to bring a higher level of well-being to your space.                </p>
                {/* <a href="#" className="text-[#191919]">LEARN MORE</a> */}
                <div className="h-2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
