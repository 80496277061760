import React from 'react';

const PreFooter = () => {
  return (
    <section className="relative flex justify-center items-center flex-wrap bg-[#f6f5f3] border-t-[8px] border-[#d8d7d5] pt-20 pb-16">
      <div className="w-full max-w-[1400px] px-4 md:px-8">
        <h2 className="text-center text-[40px] mb-8 text-[#333333] font-light">Experience Fellowes</h2>
        <p className="text-center max-w-[1000px] mx-auto mb-8 text-lg text-[#666666] leading-relaxed">
          Throughout the last 100+ years of being a family-owned business, we are continually reminded
          of our culture's most essential ingredients - we care deeply about the planet, the people we
          work alongside, and the ones we get to serve and support on a daily basis, our customers.
        </p>
        <p className="text-center mb-16 text-lg text-[#666666]">
          There are no limits to what we can accomplish together. That's the Fellowes Experience.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-[1400px] mx-auto px-4">
          <a 
            href="https://www.fellowes.com/us/en/our-company/overview/pg/family-heritage" 
            target="_blank" 
            rel="noopener noreferrer"
            className="group relative h-[400px] overflow-hidden cursor-pointer"
          >
            <div className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
                 style={{ backgroundImage: "url(//cdn.fellowes.com/assets/repositories/templates/r01/fellowes.com/production/us/en/_includes/img/homepage-imgs/family.jpg)" }}>
              <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 transition-colors duration-300"></div>
            </div>
            <div className="relative h-full flex flex-col items-center justify-center text-white p-8">
              <img src="//cdn.fellowes.com/assets/repositories/templates/r01/fellowes.com/production/us/en/_includes/img/homepage-imgs/family.svg" 
                   alt="Family" 
                   className="w-[11rem] h-[65px] mb-8" />
              <p className="text-center mb-8 text-[15px] leading-relaxed">
                Operating as a fourth-generation family business establishes our cultural foundation. It's a shared ideal among our team to align under our commitment to integrity and solidifying the relationships that matter most.
              </p>
              <div className="mt-auto text-center">
                <span className="uppercase text-[14px] tracking-wider text-white relative inline-block">
                  Our Family Heritage
                  <div className="h-[2px] bg-[#FFB81C] mx-auto mt-2 w-12 group-hover:w-full transition-all duration-300 ease-out"></div>
                </span>
              </div>
            </div>
          </a>

          <a 
            href="https://www.fellowes.com/us/en/our-company/purpose/pg/give" 
            target="_blank" 
            rel="noopener noreferrer"
            className="group relative h-[400px] overflow-hidden cursor-pointer"
          >
            <div className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
                 style={{ backgroundImage: "url(//cdn.fellowes.com/assets/repositories/templates/r01/fellowes.com/production/us/en/_includes/img/homepage-imgs/give.jpg)" }}>
              <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 transition-colors duration-300"></div>
            </div>
            <div className="relative h-full flex flex-col items-center justify-center text-white p-8">
              <img src="//cdn.fellowes.com/assets/repositories/templates/r01/fellowes.com/production/us/en/_includes/img/homepage-imgs/give.svg" 
                   alt="Give" 
                   className="w-[11rem] h-[65px] mb-8" />
              <p className="text-center mb-8 text-[15px] leading-relaxed">
                Fellowes GIVE is a purpose-led corporate initiative in which we partner with organizations to help meet some of the most fundamental human needs in our local communities.
              </p>
              <div className="mt-auto text-center">
                <span className="uppercase text-[14px] tracking-wider text-white relative inline-block">
                  Our Give Initiatives
                  <div className="h-[2px] bg-[#FFB81C] mx-auto mt-2 w-12 group-hover:w-full transition-all duration-300 ease-out"></div>
                </span>
              </div>
            </div>
          </a>

          <a 
            href="https://www.fellowes.com/us/en/our-company/purpose/pg/earth" 
            target="_blank" 
            rel="noopener noreferrer"
            className="group relative h-[400px] overflow-hidden cursor-pointer"
          >
            <div className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
                 style={{ backgroundImage: "url(//cdn.fellowes.com/assets/repositories/templates/r01/fellowes.com/production/us/en/_includes/img/homepage-imgs/earth.jpg)" }}>
              <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 transition-colors duration-300"></div>
            </div>
            <div className="relative h-full flex flex-col items-center justify-center text-white p-8">
              <img src="//cdn.fellowes.com/assets/repositories/templates/r01/fellowes.com/production/us/en/_includes/img/homepage-imgs/earth.svg" 
                   alt="Earth" 
                   className="w-[11rem] h-[65px] mb-8" />
              <p className="text-center mb-8 text-[15px] leading-relaxed">
                Earth by Fellowes represents our renewed commitment to sustainable practices at every level of our operations. Both aspirational and practical, Earth directs our steps to become an organization that pioneers new ways of doing business – ways that protect the potential of generations to come.
              </p>
              <div className="mt-auto text-center">
                <span className="uppercase text-[14px] tracking-wider text-white relative inline-block">
                  Our Sustainability Efforts
                  <div className="h-[2px] bg-[#FFB81C] mx-auto mt-2 w-12 group-hover:w-full transition-all duration-300 ease-out"></div>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default PreFooter;
