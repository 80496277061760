import { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronCircleRight } from "react-icons/fa";
import Vignettes from '../components/Vignettes';
import PageWrapper from '../components/PageWrapper';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { prevArrow } from '../assets/svgs/arrows';
import { format } from 'date-fns';
import { ThreeCircles } from 'react-loader-spinner'
import { useSearchParams } from 'react-router-dom';
import heroImage from "../assets/Bitmap.png"
import { ServiceHighlights } from '../components/ServiceHighlights';




import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'




const NavigationArrow = ({ Icon, color, className, onClick }) => {
  return (
    <>
      <Icon className={className} color={color} onClick={onClick} />
    </>
  )
}

const expandSvg = () => (
  <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="UA_ScanQR_New" transform="translate(-328.000000, -786.000000)">
              <g id="Group-22" transform="translate(0.000000, 776.000000)">
                  <g id="Group-41" transform="translate(328.000000, 10.000000)">
                      <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="31" height="31" rx="2"></rect>
                      <g id="Group-40" transform="translate(8.000000, 8.000000)" fill="#191919" fill-rule="nonzero">
                          <path d="M14.1904643,5.70253174e-08 C14.6375585,5.70253174e-08 14.9999999,0.362441498 14.9999999,0.809535673 L14.9999999,8.8235294 L13.3809287,8.8235294 L13.380528,1.61907129 L6.1764706,1.61907129 L6.1764706,5.70253174e-08 L14.1904643,5.70253174e-08 Z" id="Path-2"></path>
                          <path d="M8.01399379,6.1764706 C8.46108796,6.1764706 8.8235294,6.53891204 8.8235294,6.98600621 L8.8235294,14.9999999 L7.20445817,14.9999999 L7.2040575,7.79554183 L5.70253174e-08,7.79554183 L5.70253174e-08,6.1764706 L8.01399379,6.1764706 Z" id="Path-2" transform="translate(4.411765, 10.588235) scale(-1, -1) translate(-4.411765, -10.588235) "></path>
                          <polygon id="Path-3" transform="translate(7.500000, 7.500000) rotate(0.000000) translate(-7.500000, -7.500000) " points="13.744826 5.70253174e-08 14.9999999 1.12219089 1.25517398 14.9999999 5.70222937e-08 13.8778091"></polygon>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

const getFormattedTimestamp = (timestamp) => (
  <>
    Time Stamp - {format(new Date(timestamp), 'MM/dd/yy . hh:mm a O')}
  </>
);

const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  // prevArrow: (<FaChevronCircleLeft color="black" />),

  prevArrow: prevArrow(true, '#FFFFFF'),
  nextArrow: (<NavigationArrow Icon={FaChevronCircleRight} color="black"/>),
};

/**
 * VignetteSummary Component
 * 
 * This component displays a summary of the user's session, including their captured screenshots.
 * It serves as the final step in the user journey where they can review and interact with their session data.
 * 
 * Key Features:
 * - Displays screenshots in a carousel with thumbnail navigation
 * - Shows timestamps for each screenshot
 * - Supports both desktop and mobile views
 * - Allows users to expand images for detailed view
 */
function VignetteSummary({user, userSession, screenshots, isLoading, appointmentTime}) {

  // State to manage the thumbnail swiper instance
  const [thumbsSwiper, setThumbsSwiper] = useState(null);  

  // Check if the user is a remote attendee via URL parameter
  const [searchParams, setSearchParams] = useSearchParams();
  let isRemoteAttendee = searchParams.get("remote");
  isRemoteAttendee = isRemoteAttendee === 'true';

  // Filter screenshots to only show user-level captures
  const userLevelScreenshots = screenshots && screenshots
    .filter(screenshot => screenshot.level === 'USER')
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // const [activeIndex, setActiveIndex] = useState(0);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);


  return (
    <PageWrapper isRemoteAttendee={isRemoteAttendee} isLive={(userSession && userSession.vignetteSessions && userSession.vignetteSessions.length) || screenshots?.length > 0}>
      {
        !user ? null
        : isLoading ? (
          <ThreeCircles
            height="80"
            width="80"
            radius="9"
            color="#FDB913"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass="justify-center mt-[400px]"
          />
        ) : (
          <>
            {userLevelScreenshots && userLevelScreenshots.length ? (
              <div className="relative">
                {/* Main Image Container */}
                <div className="relative">
                  {/* Top Controls */}
                  <div className="absolute top-4 right-4 z-10 flex items-center">
                    <div className="image-info-controls">
                      Added: {format(new Date(userLevelScreenshots[currentActiveIndex].created_at), 'MM/dd/yy @ hh:mm a')}
                      <span>|</span>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          const link = document.createElement('a');
                          link.href = userLevelScreenshots[currentActiveIndex].url;
                          link.download = `screenshot-${Date.now()}.jpg`;
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5" 
                          stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Download Image
                      </a>
                    </div>
                  </div>

                  {/* Main Image */}
                  <img 
                    src={userLevelScreenshots[currentActiveIndex].url} 
                    className="w-full h-[800px] object-cover"
                    alt="Session Screenshot"
                  />

                  {/* Surface Materials Panel */}
                  <div className="absolute bottom-[190px] left-0 right-0 flex justify-center z-20">
                    <div className="surface-materials-panel">
                      <span className="surface-materials-panel-label">View our Standard Surface Materials</span>
                      <div className="surface-materials-panel-links">
                        <a
                            key={'Fabric'}
                            href={"https://www.fellowes.com/us/en/resources/downloads/pg/listing/f04/contract-interiors?q=%7B%22offset%22%3A0%2C%22chips%22%3A%5B%5D%2C%22filters%22%3A%5B%22f04-m%7Cf04-m-f%22%5D%7D"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="surface-materials-panel-link"
                          >
                            Fabric
                          </a>
                        <a
                            key={'Glass'}
                            href={"https://www.fellowes.com/us/en/resources/downloads/pg/listing/f04/contract-interiors?q=%7B%22offset%22%3A0%2C%22chips%22%3A%5B%5D%2C%22filters%22%3A%5B%22f04-m%7Cf04-c2%22%5D%7D"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="surface-materials-panel-link"
                          >
                            Glass
                          </a>
                        <a
                            key={'Laminate'}
                            href={"https://www.fellowes.com/us/en/resources/downloads/pg/listing/f04/contract-interiors?q=%7B%22offset%22%3A0%2C%22chips%22%3A%5B%5D%2C%22filters%22%3A%5B%22f04-m%7Cf04-c%22%5D%7D"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="surface-materials-panel-link"
                          >
                            Laminate
                          </a>
                        <a
                            key={'Paint'}
                            href={"https://www.fellowes.com/us/en/resources/downloads/pg/listing/f04/contract-interiors?q=%7B%22offset%22%3A0%2C%22chips%22%3A%5B%5D%2C%22filters%22%3A%5B%22f04-m%7Cf04-m-p3%22%5D%7D"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="surface-materials-panel-link"
                          >
                            Paint
                          </a>
                        <a
                            key={'Vinyl'}
                            href={"https://www.fellowes.com/us/en/resources/downloads/pg/listing/f04/contract-interiors?q=%7B%22offset%22%3A0%2C%22chips%22%3A%5B%5D%2C%22filters%22%3A%5B%22f04-m%7Cf04-m-v%22%5D%7D"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="surface-materials-panel-link"
                          >
                            Vinyl
                          </a>
                        <a
                            key={'Veneer'}
                            href={"https://www.fellowes.com/us/en/resources/downloads/pg/listing/f04/contract-interiors?q=%7B%22offset%22%3A0%2C%22chips%22%3A%5B%5D%2C%22filters%22%3A%5B%22f04-m%7Cf04-m-w%22%5D%7D"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="surface-materials-panel-link"
                          >
                            Veneer
                          </a>
                      </div>
                    </div>
                  </div>

                  {/* Main Image */}
                  <img 
                    src={userLevelScreenshots[currentActiveIndex].url} 
                    className="w-full h-[130px] object-cover object-bottom"
                    alt="Session Screenshot"
                  />
                  {/* Thumbnail Navigation Container */}
                  <div className="absolute bottom-0 left-0 right-0 thumbnail-container">  
                    <div className="relative max-w-[850px] mx-auto">
                      <div className="swiper-button-prev">
                        <svg width="21" height="33" viewBox="0 0 21 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 27L6 16.5L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <div className="swiper-button-next">
                        <svg width="21" height="33" viewBox="0 0 21 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 27L15 16.5L6 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={16}
                        slidesPerView={4}
                        freeMode={false}
                        watchSlidesProgress={true}
                        modules={[Navigation, Thumbs, FreeMode]}
                        className="thumbnail-swiper"
                        navigation={{
                          enabled: true,
                          prevEl: '.swiper-button-prev',
                          nextEl: '.swiper-button-next',
                        }}
                        breakpoints={{
                          320: {
                            centeredSlides: true,
                            // navigation: false,
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          768: {
                            centeredSlides: false,
                            slidesPerView: 4,
                            spaceBetween: 16,
                          },
                        }}



                      >
                        {userLevelScreenshots.map((screenshot, index) => (
                          <SwiperSlide key={index} className={index === currentActiveIndex ? 'custom-active-thumb' : ''}>
                            <button 
                              onClick={() => setCurrentActiveIndex(index)}
                              className="w-full h-full p-0 border-0 bg-transparent"
                            >
                              <div className="relative w-full pt-[50%]">
                                          <img
                                            className="absolute top-0 left-0 w-full h-full object-cover"
                                                              src={screenshot.url} 
                                                              alt={`Thumbnail ${index + 1}`}
                                                            />
                                          </div>
                            </button>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {
              userSession && userSession.vignetteSessions && userSession.vignetteSessions.length ? (
                <section id="section-vignettes" className="mt-[65px] md:mt-[80px] max-w-[1400px] mx-auto px-4">
                  <div className="text-[23px] md:text-[38px] text-center font-medium">Your Summary</div>
                  <Vignettes
                    className="mt-[40px]"
                    screenshots={screenshots}
                    vignettes={userSession.vignetteSessions}
                  />
                </section>
              ) : null
            }
            <ServiceHighlights />
          </>
        )
      }
    </PageWrapper>
  )
}

export default VignetteSummary
