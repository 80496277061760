// import simpleRestProvider from 'ra-data-simple-rest';
import { stringify } from 'query-string';
// import https from 'https-browserify';
import { DataProvider, fetchUtils, HttpError } from 'ra-core';
import { queryWrapper, getBaseUrl } from './index.js';


export const reactAdminFetch = (url, options = {}) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
      .then(response =>
          response.text().then(text => ({
              status: response.status,
              statusText: response.statusText,
              headers: response.headers,
              body: text,
          }))
      )
      .then(({ status, statusText, headers, body }) => {
          let json;
          try {
              json = JSON.parse(body);
          } catch (e) {
              // not json, no big deal
          }
          // localStorage.removeItem('token');
          if (status === 403 || status === 401) {
            localStorage.removeItem('token');
            window.dispatchEvent(new Event('storage'));
            return Promise.reject(
              new HttpError(
                  (json && json.message) || statusText,
                  status,
                  json
              )
            );
          }
          if (status < 200 || status >= 300) {
              return Promise.reject(
                  new HttpError(
                      (json && json.message) || statusText,
                      status,
                      json
                  )
              );
          }
          return Promise.resolve({ status, headers, body, json });
      });
};


/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export const simpleRestProvider = (
    apiUrl: string,
    httpClient = reactAdminFetch,
    // httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
        console.log('resource: ', resource);
        // const { page, perPage } = params.pagination;
        const { page } = params.pagination;

        const { field, order } = params.sort;

        const perPage = 240;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        console.log('params.filter: ', params.filter);

        let query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            encoded: true,
            filter: JSON.stringify(params.filter),
            token: localStorage.getItem('token'),
        };
        query = queryWrapper(query);

        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options =
            countHeader === 'Content-Range'
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: new Headers({
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }),
                } : {};

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            return {
                data: json,
                total:
                    countHeader === 'Content-Range'
                        ? parseInt(
                              headers.get('content-range').split('/').pop(),
                              10
                          )
                        : parseInt(headers.get(countHeader.toLowerCase())),
            };
        });
    },

    getOne: (resource, params) =>
        {
            let query = {
                encoded: true,
                token: localStorage.getItem('token'),
            };
            query = queryWrapper(query);

            console.log('getting one: ');

            return httpClient(`${apiUrl}/${resource}/${params.id}?${stringify(query)}`).then(({ json }) => ({
                data: json,
            }));
        },

    getMany: (resource, params) => {
        console.log('getmany');
        let query = {
            encoded: true,
            filter: JSON.stringify({ id: params.ids }),
            token: localStorage.getItem('token'),
        };
        query = queryWrapper(query);

        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        console.log('getmanyref');

        // const { page, perPage } = params.pagination;
        const { page } = params.pagination;

        const { field, order } = params.sort;

        const perPage = 240;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        let query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            encoded: true,
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
            token: localStorage.getItem('token'),
        };
        query = queryWrapper(query);


        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options =
            countHeader === 'Content-Range'
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: new Headers({
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }),
                  }
                : {};

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            return {
                data: json,
                total:
                    countHeader === 'Content-Range'
                        ? parseInt(
                              headers.get('content-range').split('/').pop(),
                              10
                          )
                        : parseInt(headers.get(countHeader.toLowerCase())),
            };
        });
    },

    update: (resource, params) =>
        {
            let query = {
                encoded: true,
                token: localStorage.getItem('token'),
            };
            query = queryWrapper(query);


            delete params.data.main_aws_s3_key;
            
            return httpClient(`${apiUrl}/${resource}/${params.id}?${stringify(query)}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json }));
        },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        {
            let query = {
                encoded: true,
                token: localStorage.getItem('token'),
            };
            query = queryWrapper(query);

            
            return Promise.all(
                params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}?${stringify(query)}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
                )
            ).then(responses => ({ data: responses.map(({ json }) => json.id) }));
        },

    create: async (resource, params) =>
        {
            let query = {
                encoded: true,
                token: localStorage.getItem('token'),
            };
            query = queryWrapper(query);

            console.log('params.data: ', params.data);

            const {
                isMedia
            } = params.data;

            if (isMedia) {
                const {
                    file: { rawFile, title: fileName },
                } = params.data;

                console.log('file: ', rawFile);
                console.log('typeof file: ', typeof rawFile);
                console.log('file type: ', rawFile.type);

                let filename = fileName;
                
                let mediaType = 'undefined';
                if (rawFile.type === 'image/jpeg' || rawFile.type === 'image/jpg' || rawFile.type === 'image/png') {
                    mediaType = 'image';
                } else if (rawFile.type === 'video/mp4') {
                    mediaType = 'video';
                } else {
                    throw new Error({ message: "File type must be one of: [image/jpeg, image/jpg, image/png, video/mp4]" });
                }

                const removeSpecialCharsFromFilename = str => {
                    const splitString = str.split('.');
                    const extension = splitString.pop();
                    const filename = splitString.join('');
                    return filename.replace(/[^a-zA-Z0-9]/g, '') + '.' + extension;
                };

                let userId = null;
                filename = `${userId}-${Date.now()}-${removeSpecialCharsFromFilename(filename)}`;
            
                let filePrefix;
                let thumbnailPrefix;
                
                if (resource === 'wall-graphics') {
                    const { collage_marker_id } = params.data;
                    filePrefix = process.env.REACT_APP_ENV === 'production'
                        ? `prod/wallGraphics/${collage_marker_id || 'static'}`
                        : `wallGraphics/${collage_marker_id || 'static'}`;
                    thumbnailPrefix = process.env.REACT_APP_ENV === 'production'
                    ? `prod/wallGraphics/${collage_marker_id || 'static'}/thumbnail`
                    : `wallGraphics/${collage_marker_id || 'static'}/thumbnail`;
                } else {
                    filePrefix = process.env.REACT_APP_ENV === 'production'
                        ? `prod/collageImages/${mediaType}`
                        : `collageImages/${mediaType}`;
                    thumbnailPrefix = process.env.REACT_APP_ENV === 'production'
                        ? `prod/collageImages/thumbnail`
                        : `collageImages/thumbnail`;
                }

                const changeFileExtension = (filename, newExtension) => {
                    const splitString = filename.split('.');
                    splitString.pop();
                    const filenameWithNewExt = splitString.join('') + '.' + newExtension;
                    return filenameWithNewExt;
                }

                const imagePath = `${filePrefix}/${filename}`
                const thumbnailPath = `${thumbnailPrefix}/${filename}`;

                const s3BasePath = 'https://fultonmarket-dev.s3.us-west-1.amazonaws.com';

                const url = `${s3BasePath}/${imagePath}`;
                const thumbnail_url = `${s3BasePath}/${changeFileExtension(thumbnailPath, 'jpg')}`;

                console.log('url: ', url);
                console.log('thumbnail: ', thumbnail_url);

                query.filename = imagePath;
                query.fileType = rawFile.type;

                const presignedUrlResp = await httpClient(
                    `${apiUrl}/aws/presignedS3Url?${stringify(query)}`,
                    { method: 'GET' }
                );
                const { presignedURL } = presignedUrlResp.json;

                const headers = { 'Content-Length': rawFile.size }

                const response = await fetch(presignedURL, {
                    method: "PUT",
                    headers: headers,
                    body: rawFile
                });

                params.data.url = url;
                params.data.thumbnail_url = thumbnail_url;
                params.data.type = mediaType;
                delete params.data.file;

                if (resource === 'insightToken/media') {
                    console.log('insightToken/media specific logic')    
                    let {
                        sub_cat,
                    } = params.data;

                    params.data.insight_token_sub_category_id = sub_cat.id;
                } //else if (resource === 'markerboard') {
                    // Add any markerboard-specific logic here
                   // params.data.type = 'image';  // Always image for markerboards
                //}

                console.log('params.data: ', params.data);
    
                console.log('presignedUrlResp: ', presignedURL);

                return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({ data: json }))  
            } else {
                return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({ data: json }))  
            }
        },

    delete: (resource, params) =>
        {
            let query = {
                encoded: true,
                token: localStorage.getItem('token'),
            };
            query = queryWrapper(query);


            return httpClient(`${apiUrl}/${resource}/${params.id}?${stringify(query)}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'text/plain',
                }),
            }).then(({ json }) => ({ data: json }));
        },

    deleteMany: (resource, params) =>
        {
            let query = {
                encoded: true,
                token: localStorage.getItem('token'),
            };
            query = queryWrapper(query);


            return Promise.all(
                params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}?${stringify(query)}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
                )
            ).then(responses => ({
                data: responses.map(({ json }) => json.id),
            }));
        },
});


export const dataProvider = simpleRestProvider(
    getBaseUrl()
);
