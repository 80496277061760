import { JsonField, JsonInput } from "react-admin-json-view";
import {
    Admin,
    AutocompleteInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    List,
    NumberField,
    NumberInput,
    required,
    Resource,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin';
import { CMSLayout } from '../../../components/CMSUtils';
import { dataProvider } from '../../../utils/cmsDataProvider';
import { getDisplayName } from '../../../utils/metaData.js';


const typeInputOptions = [
    { id: 'BASE_PRODUCT', name: 'Base Product' },
    { id: 'MATERIAL', name: 'Material' },
];

const keyInputOptions = [
    { id: 'clear_glass_monolithic', name: 'clear_glass_monolithic' },
    { id: 'clear_glass_low_iron_monolithic', name: 'clear_glass_low_iron_monolithic' },
    { id: 'clear_glass_laminated', name: 'clear_glass_laminated' },
    { id: 'frost_glass', name: 'frost_glass' },
    { id: 'textured_glass_reeded', name: 'textured_glass_reeded' },
    { id: 'textured_glass_aquatex', name: 'textured_glass_aquatex' },
    { id: 'backpainted_glass_designer_white', name: 'backpainted_glass_designer_white' },
    { id: 'backpainted_glass_platinum', name: 'backpainted_glass_platinum' },
    { id: 'backpainted_glass_black', name: 'backpainted_glass_black' },
    { id: 'backpainted_glass_stone', name: 'backpainted_glass_stone' },
    { id: 'backpainted_glass_storm', name: 'backpainted_glass_storm' },
    { id: 'backpainted_glass_cloud', name: 'backpainted_glass_cloud' },
    { id: 'backpainted_glass_matcha', name: 'backpainted_glass_matcha' },
    { id: 'backpainted_glass_clay', name: 'backpainted_glass_clay' },
    { id: 'backpainted_glass_surf', name: 'backpainted_glass_surf' },
    { id: 'etched_glass_sturm', name: 'etched_glass_sturm' },
    { id: 'etched_glass_horizon', name: 'etched_glass_horizon' },
    { id: 'etched_glass_sateen', name: 'etched_glass_sateen' },
    { id: 'etched_glass_branch', name: 'etched_glass_branch' },
    { id: 'etched_glass_cloy', name: 'etched_glass_cloy' },
    { id: 'etched_glass_sateen_bronze', name: 'etched_glass_sateen_bronze' },
    { id: 'resin_horizon_day', name: 'resin_horizon_day' },
    { id: 'resin_strand_white', name: 'resin_strand_white' },
    { id: 'resin_veild_coastline', name: 'resin_veild_coastline' },
    { id: 'resin_hem_ashland', name: 'resin_hem_ashland' },
    { id: 'resin_hem_thames', name: 'resin_hem_thames' },
    { id: 'resin_carbon_2_quattro', name: 'resin_carbon_2_quattro' },
    { id: 'resin_vita_orbit', name: 'resin_vita_orbit' },
    { id: 'resin_traffic_clear', name: 'resin_traffic_clear' },
    { id: 'resin_connection_pure', name: 'resin_connection_pure' },
    { id: 'resin_fray_smoke', name: 'resin_fray_smoke' },
    { id: 'laminate_charcoal', name: 'laminate_charcoal' },
    { id: 'laminate_light_gray', name: 'laminate_light_gray' },
    { id: 'laminate_black', name: 'laminate_black' },
    { id: 'laminate_designer_white', name: 'laminate_designer_white' },
    { id: 'laminate_beigewood', name: 'laminate_beigewood' },
    { id: 'laminate_shadow_oak', name: 'laminate_shadow_oak' },
    { id: 'laminate_grey', name: 'laminate_grey' },
    { id: 'laminate_dove_grey', name: 'laminate_dove_grey' },
    { id: 'laminate_manitoba_maple', name: 'laminate_manitoba_maple' },
    { id: 'laminate_pinnacle_walnut', name: 'laminate_pinnacle_walnut' },
    { id: 'laminate_linen', name: 'laminate_linen' },
    { id: 'laminate_antique_white', name: 'laminate_antique_white' },
    { id: 'laminate_platinum', name: 'laminate_platinum' },
    { id: 'laminate_khaki_brown', name: 'laminate_khaki_brown' },
    { id: 'laminate_shadow', name: 'laminate_shadow' },
    { id: 'laminate_slate_gray', name: 'laminate_slate_gray' },
    { id: 'laminate_walnut_heights', name: 'laminate_walnut_heights' },
    { id: 'laminate_asian_night', name: 'laminate_asian_night' },
    { id: 'laminate_stucco', name: 'laminate_stucco' },
    { id: 'laminate_sand', name: 'laminate_sand' },
    { id: 'laminate_graphite', name: 'laminate_graphite' },
    { id: 'laminate_brazilwood', name: 'laminate_brazilwood' },
    { id: 'laminate_empire_mahogany', name: 'laminate_empire_mahogany' },
    { id: 'laminate_espresso_pearwood', name: 'laminate_espresso_pearwood' },
    { id: 'lamiante_finnish_oak', name: 'lamiante_finnish_oak' },
    { id: 'laminate_fusion_maple', name: 'laminate_fusion_maple' },
    { id: 'laminate_phantom_ecru', name: 'laminate_phantom_ecru' },
    { id: 'laminate_phantom_pearl', name: 'laminate_phantom_pearl' },
    { id: 'laminate_phantom_charcoal', name: 'laminate_phantom_charcoal' },
    { id: 'laminate_walnut', name: 'laminate_walnut' },
    { id: 'laminate_daintree', name: 'laminate_daintree' },
    { id: 'laminate_dering_forest', name: 'laminate_dering_forest' },
    { id: 'laminate_field_elm', name: 'laminate_field_elm' },
    { id: 'laminate_uptown_walnut', name: 'laminate_uptown_walnut' },
    { id: 'laminate_neowalnut', name: 'laminate_neowalnut' },
    { id: 'laminate_wild_cherry', name: 'laminate_wild_cherry' },
    { id: 'laminate_crisp_linen', name: 'laminate_crisp_linen' },
    { id: 'laminate_classic_linen', name: 'laminate_classic_linen' },
    { id: 'laminate_casual_linen', name: 'laminate_casual_linen' },
    { id: 'laminate_white_tigris', name: 'laminate_white_tigris' },
    { id: 'laminate_evening_tigris', name: 'laminate_evening_tigris' },
    { id: 'laminate_sarum_twill', name: 'laminate_sarum_twill' },
    { id: 'laminate_earthen_twill', name: 'laminate_earthen_twill' },
    { id: 'laminate_graphite_twill', name: 'laminate_graphite_twill' },
    { id: 'veneer_beigewood', name: 'veneer_beigewood' },
    { id: 'veneer_fusion_maple', name: 'veneer_fusion_maple' },
    { id: 'veneer_brazilwood', name: 'veneer_brazilwood' },
    { id: 'veneer_wild_cherry', name: 'veneer_wild_cherry' },
    { id: 'veneer_empire_mahogany', name: 'veneer_empire_mahogany' },
    { id: 'painted_finish_satin_etch', name: 'painted_finish_satin_etch' },
    { id: 'painted_finish_bronze', name: 'painted_finish_bronze' },
    { id: 'painted_finish_slate', name: 'painted_finish_slate' },
    { id: 'painted_finish_slate_light', name: 'painted_finish_slate_light' },
    { id: 'painted_finish_azure', name: 'painted_finish_azure' },
    { id: 'painted_finish_azure_light', name: 'painted_finish_azure_light' },
    { id: 'painted_finish_sage', name: 'painted_finish_sage' },
    { id: 'painted_finish_stardust', name: 'painted_finish_stardust' },
    { id: 'painted_finish_bright_silver', name: 'painted_finish_bright_silver' },
    { id: 'painted_finish_polished_aluminum', name: 'painted_finish_polished_aluminum' },
    { id: 'painted_finish_designer_white', name: 'painted_finish_designer_white' },
    { id: 'painted_finish_designer_white_textured', name: 'painted_finish_designer_white_textured' },
    { id: 'painted_finish_light_gray', name: 'painted_finish_light_gray' },
    { id: 'painted_finish_light_gray_textured', name: 'painted_finish_light_gray_textured' },
    { id: 'painted_finish_sand', name: 'painted_finish_sand' },
    { id: 'painted_finish_sand_textured', name: 'painted_finish_sand_textured' },
    { id: 'painted_finish_gray', name: 'painted_finish_gray' },
    { id: 'painted_finish_gray_textured', name: 'painted_finish_gray_textured' },
    { id: 'painted_finish_charcoal', name: 'painted_finish_charcoal' },
    { id: 'painted_finish_charcoal_textured', name: 'painted_finish_charcoal_textured' },
    { id: 'painted_finish_platinum', name: 'painted_finish_platinum' },
    { id: 'painted_finish_silver', name: 'painted_finish_silver' },
    { id: 'painted_finish_black', name: 'painted_finish_black' },
    { id: 'painted_finish_white', name: 'painted_finish_white' },
    { id: 'framework_cappuccino', name: 'framework_cappuccino' },
    { id: 'framework_dove_gray', name: 'framework_dove_gray' },
    { id: 'framework_espresso', name: 'framework_espresso' },
    { id: 'framework_eucalyptus', name: 'framework_eucalyptus' },
    { id: 'framework_gray_blue', name: 'framework_gray_blue' },
    { id: 'framework_grotto', name: 'framework_grotto' },
    { id: 'framework_hunter', name: 'framework_hunter' },
    { id: 'framework_marble', name: 'framework_marble' },
    { id: 'framework_sandy_pebble', name: 'framework_sandy_pebble' },
    { id: 'framework_twilight', name: 'framework_twilight' },
    { id: 'merge_dove', name: 'merge_dove' },
    { id: 'merge_driftwood', name: 'merge_driftwood' },
    { id: 'merge_eucalyptus', name: 'merge_eucalyptus' },
    { id: 'merge_fawn', name: 'merge_fawn' },
    { id: 'merge_fire_engine', name: 'merge_fire_engine' },
    { id: 'merge_graphite', name: 'merge_graphite' },
    { id: 'merge_green_apple', name: 'merge_green_apple' },
    { id: 'merge_ivy', name: 'merge_ivy' },
    { id: 'merge_mandarin', name: 'merge_mandarin' },
    { id: 'merge_marshmallow', name: 'merge_marshmallow' },
    { id: 'merge_midnight', name: 'merge_midnight' },
    { id: 'merge_slate', name: 'merge_slate' },
    { id: 'amaze_morel', name: 'amaze_morel' },
    { id: 'amaze_platinum', name: 'amaze_platinum' },
    { id: 'amaze_sky', name: 'amaze_sky' },
    { id: 'amaze_steel', name: 'amaze_steel' },
    { id: 'aurora_cloud', name: 'aurora_cloud' },
    { id: 'aurora_frost', name: 'aurora_frost' },
    { id: 'aurora_granite', name: 'aurora_granite' },
    { id: 'aurora_latte', name: 'aurora_latte' },
    { id: 'aurora_pewter', name: 'aurora_pewter' },
    { id: 'chase_coin', name: 'chase_coin' },
    { id: 'chase_ecru', name: 'chase_ecru' },
    { id: 'chase_mushroom', name: 'chase_mushroom' },
    { id: 'chase_pumice', name: 'chase_pumice' },
    { id: 'chase_shadow', name: 'chase_shadow' },
    { id: 'intermix_foggy', name: 'intermix_foggy' },
    { id: 'intermix_husky', name: 'intermix_husky' },
    { id: 'intermix_neptune', name: 'intermix_neptune' },
    { id: 'intermix_rocket_ship', name: 'intermix_rocket_ship' },
    { id: 'intermix_seagull', name: 'intermix_seagull' },
    { id: 'intermix_smoky_quartz', name: 'intermix_smoky_quartz' },
    { id: 'intermix_swan', name: 'intermix_swan' },
    { id: 'mingle_biscuit', name: 'mingle_biscuit' },
    { id: 'mingle_carbon', name: 'mingle_carbon' },
    { id: 'mingle_crystal', name: 'mingle_crystal' },
    { id: 'mingle_wheatberry', name: 'mingle_wheatberry' },
    { id: 'mingle_winter', name: 'mingle_winter' },
    { id: 'netiquette_analog', name: 'netiquette_analog' },
    { id: 'netiquette_chipset', name: 'netiquette_chipset' },
    { id: 'netiquette_dashboard', name: 'netiquette_dashboard' },
    { id: 'netiquette_gamma', name: 'netiquette_gamma' },
    { id: 'netiquette_inkjet', name: 'netiquette_inkjet' },
    { id: 'netiquette_pixel', name: 'netiquette_pixel' },
    { id: 'netiquette_refresh', name: 'netiquette_refresh' },
    { id: 'netiquette_schema', name: 'netiquette_schema' },
    { id: 'netiquette_vector', name: 'netiquette_vector' },
    { id: 'pursuit_cement', name: 'pursuit_cement' },
    { id: 'pursuit_dove', name: 'pursuit_dove' },
    { id: 'pursuit_elephant', name: 'pursuit_elephant' },
    { id: 'pursuit_mist_grey', name: 'pursuit_mist_grey' },
    { id: 'pursuit_whisper', name: 'pursuit_whisper' },
    { id: 'pursuit_white_linen', name: 'pursuit_white_linen' },
    { id: 'pact_aloe', name: 'pact_aloe' },
    { id: 'pact_coastal', name: 'pact_coastal' },
    { id: 'pact_dove', name: 'pact_dove' },
    { id: 'pact_fern', name: 'pact_fern' },
    { id: 'pact_harbour', name: 'pact_harbour' },
    { id: 'pact_midnight', name: 'pact_midnight' },
    { id: 'pact_patina', name: 'pact_patina' },
    { id: 'pact_putty', name: 'pact_putty' },
    { id: 'pact_sketch', name: 'pact_sketch' },
    { id: 'pact_taupe', name: 'pact_taupe' },
    { id: 'pact_toasty', name: 'pact_toasty' },
    { id: 'tabby_weave_cement_mix', name: 'tabby_weave_cement_mix' },
    { id: 'tabby_weave_crystal_blue', name: 'tabby_weave_crystal_blue' },
    { id: 'tabby_weave_grey_mix', name: 'tabby_weave_grey_mix' },
    { id: 'tabby_weave_medium_gray', name: 'tabby_weave_medium_gray' },
    { id: 'tabby_weave_quartz', name: 'tabby_weave_quartz' },
    { id: 'tabby_weave_silver_papier', name: 'tabby_weave_silver_papier' },
    { id: 'tabby_weave_wedgewood', name: 'tabby_weave_wedgewood' },
    { id: 'anchorage_angora', name: 'anchorage_angora' },
    { id: 'anchorage_asteroid', name: 'anchorage_asteroid' },
    { id: 'anchorage_aubergine', name: 'anchorage_aubergine' },
    { id: 'anchorage_birch', name: 'anchorage_birch' },
    { id: 'anchorage_cobalt', name: 'anchorage_cobalt' },
    { id: 'anchorage_deep_water', name: 'anchorage_deep_water' },
    { id: 'anchorage_graphite', name: 'anchorage_graphite' },
    { id: 'anchorage_green_apple', name: 'anchorage_green_apple' },
    { id: 'anchorage_lapis', name: 'anchorage_lapis' },
    { id: 'anchorage_midnight', name: 'anchorage_midnight' },
    { id: 'anchorage_onyx', name: 'anchorage_onyx' },
    { id: 'anchorage_pool', name: 'anchorage_pool' },
    { id: 'anchorage_pumpkin', name: 'anchorage_pumpkin' },
    { id: 'anchorage_quarry_blue', name: 'anchorage_quarry_blue' },
    { id: 'anchorage_red_delicious', name: 'anchorage_red_delicious' },
    { id: 'anchorage_slate', name: 'anchorage_slate' },
    { id: 'anchorage_thistle', name: 'anchorage_thistle' },
    { id: 'anchorage_vanilla', name: 'anchorage_vanilla' },
    { id: 'anchorage_waterfall', name: 'anchorage_waterfall' },
    { id: 'anchorage_willow', name: 'anchorage_willow' },
    { id: 'anchorage_wolf', name: 'anchorage_wolf' },
    { id: 'glint_caspian', name: 'glint_caspian' },
    { id: 'glint_column', name: 'glint_column' },
    { id: 'glint_gypsum', name: 'glint_gypsum' },
    { id: 'glint_opal', name: 'glint_opal' },
    { id: 'glint_pumice', name: 'glint_pumice' },
    { id: 'glint_relic', name: 'glint_relic' },
    { id: 'glint_serene', name: 'glint_serene' },
    { id: 'glint_shale', name: 'glint_shale' },
    { id: 'product_titan', name: 'product_titan' },
    { id: 'product_trig', name: 'product_trig' },
    { id: 'product_volo_framed', name: 'product_volo_framed' },
    { id: 'product_volo_frameless', name: 'product_volo_frameless' },
    { id: 'product_elea', name: 'product_elea' },
    { id: 'product_victory', name: 'product_victory' },
    { id: 'product_capture', name: 'product_capture' },
    { id: 'product_choices', name: 'product_choices' },
    { id: 'product_zona', name: 'product_zona' },
    { id: 'product_intrinsic', name: 'product_intrinsic' },
    { id: 'product_rising', name: 'product_rising' },
    { id: 'product_rising_loft', name: 'product_rising_loft' },
    { id: 'product_sena', name: 'product_sena' },
    { id: 'product_lustre', name: 'product_lustre' },
    { id: 'product_flex_charge', name: 'product_flex_charge' },
    { id: 'product_hana_foot_rest', name: 'product_hana_foot_rest' },
    { id: 'product_hana_laptop_stand', name: 'product_hana_laptop_stand' },
    { id: 'product_array', name: 'product_array' },
    { id: 'etched_glass_cascade', name: 'etched_glass_cascade' },
    { id: 'etched_glass_sateen_batiste', name: 'etched_glass_sateen_batiste' },
    { id: 'etched_glass_sateen_ar110', name: 'etched_glass_sateen_ar110' },
    { id: 'etched_glass_sateen_shimmer', name: 'etched_glass_sateen_shimmer' },
    { id: 'etched_glass_sateen_lines', name: 'etched_glass_sateen_lines' },
    { id: 'etched_doubleside_glass_sateen_opt2', name: 'etched_doubleside_glass_sateen_opt2' },
    { id: 'etched_doubleside_glass_sateen_bronze', name: 'etched_doubleside_glass_sateen_bronze' },
    { id: 'printed_sateen_warm', name: 'printed_sateen_warm' },
    { id: 'printed_sateen_cool', name: 'printed_sateen_cool' },
    { id: 'printed_sateen_light', name: 'printed_sateen_light' },
    { id: 'printed_sateen_dark', name: 'printed_sateen_dark' },
    { id: 'backpainted_glass_graphite', name: 'backpainted_glass_graphite' },
    { id: 'digital_shift_grid_light', name: 'digital_shift_grid_light' },
    { id: 'digital_shift_grid_warm', name: 'digital_shift_grid_warm' },
    { id: 'digital_shift_grid_cool', name: 'digital_shift_grid_cool' },
    { id: 'digital_shift_grid_dark', name: 'digital_shift_grid_dark' },
    { id: 'digital_lineal_fade_light', name: 'digital_lineal_fade_light' },
    { id: 'digital_lineal_fade_warm', name: 'digital_lineal_fade_warm' },
    { id: 'digital_lineal_fade_cool', name: 'digital_lineal_fade_cool' },
    { id: 'digital_lineal_fade_dark', name: 'digital_lineal_fade_dark' },
    { id: 'digitally_printed_lines_light', name: 'digitally_printed_lines_light' },
    { id: 'digitally_printed_lines_warm', name: 'digitally_printed_lines_warm' },
    { id: 'digitally_printed_lines_cool', name: 'digitally_printed_lines_cool' },
    { id: 'digitally_printed_lines_dark', name: 'digitally_printed_lines_dark' },
    { id: 'digitally_printed_sateen_light', name: 'digitally_printed_sateen_light' },
    { id: 'digitally_printed_sateen_warm', name: 'digitally_printed_sateen_warm' },
    { id: 'digitally_printed_sateen_cool', name: 'digitally_printed_sateen_cool' },
    { id: 'digitally_printed_sateen_dark', name: 'digitally_printed_sateen_dark' },
    { id: 'printed_shiftgrid_satinlite_light', name: 'printed_shiftgrid_satinlite_light' },
    { id: 'printed_shiftgrid_satinlite_warm', name: 'printed_shiftgrid_satinlite_warm' },
    { id: 'printed_shiftgrid_satinlite_cool', name: 'printed_shiftgrid_satinlite_cool' },
    { id: 'printed_shiftgrid_satinlite_dark', name: 'printed_shiftgrid_satinlite_dark' },
    { id: 'printed_linealfade_satinlite_light', name: 'printed_linealfade_satinlite_light' },
    { id: 'printed_linealfade_satinlite_warm', name: 'printed_linealfade_satinlite_warm' },
    { id: 'printed_linealfade_satinlite_cool', name: 'printed_linealfade_satinlite_cool' },
    { id: 'printed_linealfade_satinlite_dark', name: 'printed_linealfade_satinlite_dark' },
    { id: 'printed_lines_satinlite_light', name: 'printed_lines_satinlite_light' },
    { id: 'printed_lines_satinlite_warm', name: 'printed_lines_satinlite_warm' },
    { id: 'printed_lines_satinlite_cool', name: 'printed_lines_satinlite_cool' },
    { id: 'printed_lines_satinlite_dark', name: 'printed_lines_satinlite_dark' },
    { id: 'printed_sateen_satinlite_light', name: 'printed_sateen_satinlite_light' },
    { id: 'printed_sateen_satinlite_warm', name: 'printed_sateen_satinlite_warm' },
    { id: 'printed_sateen_satinlite_cool', name: 'printed_sateen_satinlite_cool' },
    { id: 'printed_sateen_satinlite_dark', name: 'printed_sateen_satinlite_dark' },
    { id: 'anchorage_eucalyptus', name: 'anchorage_eucalyptus' },
    { id: 'anchorage_geranium', name: 'anchorage_geranium' },
    { id: 'anchorage_goose', name: 'anchorage_goose' },
    { id: 'anchorage_mulberry', name: 'anchorage_mulberry' },
    { id: 'anchorage_shadow', name: 'anchorage_shadow' },
    { id: 'anchorage_sunshine', name: 'anchorage_sunshine' },
    { id: 'marin_dolphin', name: 'marin_dolphin' },
    { id: 'marin_grey_whale', name: 'marin_grey_whale' },
    { id: 'purpose_ambition', name: 'purpose_ambition' },
    { id: 'purpose_wish', name: 'purpose_wish' },
    { id: 'palette_cutch', name: 'palette_cutch' },
    { id: 'palette_iron', name: 'palette_iron' },
    { id: 'resolve_fortitude', name: 'resolve_fortitude' },
    { id: 'resolve_zeal', name: 'resolve_zeal' },
    { id: 'vinyl_designer_white', name: 'vinyl_designer_white' },
    { id: 'vinyl_light_gray', name: 'vinyl_light_gray' },
    { id: 'vinyl_sand', name: 'vinyl_sand' },
    { id: 'vinyl_gray', name: 'vinyl_gray' },
    { id: 'vinyl_charcoal', name: 'vinyl_charcoal' },
    { id: 'snowdrop', name: 'snowdrop' },
    { id: 'horizon', name: 'horizon' },
    { id: 'atom', name: 'atom' },
    { id: 'almond', name: 'almond' },
    { id: 'truffle', name: 'truffle' },
    { id: 'tungsten', name: 'tungsten' },
    { id: 'fawn', name: 'fawn' },
    { id: 'galaxy', name: 'galaxy' },
    { id: 'denim', name: 'denim' },
    { id: 'olive', name: 'olive' },
    { id: 'isle', name: 'isle' },
    { id: 'aged_wall', name: 'aged_wall' },
    { id: 'walnut_grey', name: 'walnut_grey' },
    { id: 'walnut_raw', name: 'walnut_raw' },
    { id: 'walnut_jacobean', name: 'walnut_jacobean' },
    { id: 'cascade', name: 'cascade' },
    { id: 'batiste', name: 'batiste' },
    { id: 'ar110', name: 'ar110' },
    { id: 'shimmer', name: 'shimmer' },
    { id: 'sateen', name: 'sateen' },
    { id: 'lines', name: 'lines' },
    { id: 'markerboard_white', name: 'markerboard_white' },
    { id: 'markerboard_cool_gray', name: 'markerboard_cool_gray' },
    { id: 'markerboard_black', name: 'markerboard_black' },
    { id: 'markerboard_blush', name: 'markerboard_blush' },
    { id: 'markerboard_rose', name: 'markerboard_rose' },
    { id: 'markerboard_glacier', name: 'markerboard_glacier' },
    { id: 'markerboard_pacific', name: 'markerboard_pacific' },
    { id: 'markerboard_leaf', name: 'markerboard_leaf' },
    { id: 'markerboard_olive', name: 'markerboard_olive' },
    { id: 'markerboard_timber', name: 'markerboard_timber' },
    { id: 'markerboard_coral', name: 'markerboard_coral' },
    { id: 'markerboard_goldenrod', name: 'markerboard_goldenrod' },
    { id: 'markerboard_casters_white', name: 'markerboard_casters_white' },
    { id: 'markerboard_casters_gray', name: 'markerboard_casters_gray' },
    { id: 'markerboard_casters_black', name: 'markerboard_casters_black' },
    { id: 'markerboard_glass_high_gloss', name: 'markerboard_glass_high_gloss' },
    { id: 'markerboard_glass_satin_etch', name: 'markerboard_glass_satin_etch' },
    { id: 'MB_Acacia_HT', name: 'MB_Acacia_HT' },
    { id: 'MB_AcaciaLogo_V_Transp', name: 'MB_AcaciaLogo_V_Transp' },
    { id: 'MB_AcaciaLogo_V', name: 'MB_AcaciaLogo_V' },
    { id: 'MB_Allergies', name: 'MB_Allergies' },
    { id: 'MB_AcaciaLogo_V2', name: 'MB_AcaciaLogo_V2' },
    { id: 'MB_Basketball', name: 'MB_Basketball' },
    { id: 'MB_GraphPaper', name: 'MB_GraphPaper' },
    { id: 'MB_Itasca', name: 'MB_Itasca' },
    { id: 'MB_MedicalCenter_HT', name: 'MB_MedicalCenter_HT' },
    { id: 'CWD_Nature', name: 'CWD_Nature' },
    { id: 'CWD_education', name: 'CWD_education' },
];

export const NameField = (props) => {
    const record = useRecordContext(props);
    return record ? <span>{getDisplayName(record.item_key)}</span> : null;
}

export const LeadtimingandpricingList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <NameField label="Item name" />
            <TextField source="item_key" />
            <TextField source="description" />
            <TextField source="type" />
            <TextField source="list_price_usd" />
            <NumberField source="max_lead_time_in_days" />
            <NumberField source="min_lead_time_in_days" />
            <JsonField source="meta_data" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export const LeadtimingandpricingEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="id" validate={[required()]}/>
            <AutocompleteInput source="item_key" choices={keyInputOptions} validate={[required()]} />
            {/* <TextInput source="item_key" validate={[required()]}/> */}
            <TextInput source="description" validate={[required()]}/>
            {/* <TextInput source="type" /> */}
            <SelectInput source="type" choices={typeInputOptions} validate={[required()]}/>
            <TextInput source="list_price_usd" validate={[required()]}/>
            <NumberInput source="max_lead_time_in_days" validate={[required()]}/>
            <NumberInput source="min_lead_time_in_days" validate={[required()]}/>
            <JsonInput source="meta_data" />
        </SimpleForm>
    </Edit>
);


export const LeadtimingandpricingCreate = () => (
      <Create>
        <SimpleForm>
            <AutocompleteInput source="item_key" choices={keyInputOptions} validate={[required()]} />
            {/* <TextInput source="item_key" validate={[required()]}/> */}
            <TextInput source="description" validate={[required()]}/>
            {/* <TextInput source="type" /> */}
            <SelectInput source="type" choices={typeInputOptions} validate={[required()]}/>
            <TextInput source="list_price_usd" validate={[required()]}/>
            <NumberInput source="max_lead_time_in_days" validate={[required()]}/>
            <NumberInput source="min_lead_time_in_days" validate={[required()]}/>
            <JsonInput source="meta_data" />
        </SimpleForm>
      </Create>
    );

export const CMS = () => (
    <Admin
        dataProvider={dataProvider}
		// authProvider={authProvider}
        basename='/admin/leadTimingAndPricing'
        layout={CMSLayout}
	>
        <Resource name="leadTimingAndPricing" options={{ label: 'Lead Timing and Pricing' }} list={LeadtimingandpricingList} edit={LeadtimingandpricingEdit} create={LeadtimingandpricingCreate} />
    </Admin>
);
