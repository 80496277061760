import React from 'react';
import emailIcon from '../assets/emailicon.png';
import textIcon from '../assets/texticon.png';
import printIcon from '../assets/printicon.png';
import './Share.css';

const Share = () => {
  return (
    <section className="share pt-[60px]">
      <div className="share__container">
        <h2 className="text-center text-[30px] mb-4 text-[#333333] font-light">Share Your Designs</h2>
        
        <div className="share__buttons">
          {/* Email Button */}
          <a
            href={`mailto:?to=&body=${window.location.href}&subject=Portray by Fellowes Remote Experience Invite`}
            target="_blank"
            rel="noopener noreferrer"
            className="share__button"
          >
            <div className="share__button-icon">
              <img src={emailIcon} alt="Email" />
            </div>
            <span className="share__button-text">
              Email
            </span>
          </a>

          {/* Text Button */}
          <a
            href={`sms:+18664504185?&body=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share__button"
          >
            <div className="share__button-icon">
              <img src={textIcon} alt="Text" />
            </div>
            <span className="share__button-text">
              Text
            </span>
          </a>

          {/* Print Button */}
          {/* <button
            onClick={() => window.print()}
            className="share__button"
          >
            <div className="share__button-icon">
              <img src={printIcon} alt="Print" />
            </div>
            <span className="share__button-text">
              Print
            </span>
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default Share;