import { useState } from "react";
import { format } from 'date-fns';
import logo from "../assets/Logo.png"
import Contact from '../components/Contact.jsx';
import Share from '../components/Share.jsx';
import Footer from '../components/Footer.jsx';
import Rating from '../components/Rating.jsx';
import Header from '../components/Header.jsx';
import ProductPillars from '../components/ProductPillars.jsx';
import PreFooter from '../components/PreFooter.jsx';
import Hero from '../assets/Hero.png'
import MobileHero from '../assets/MobileHero.jpg';

function PageWrapper({ children, isRemoteAttendee, appointmentTime, isLive }) {
  // const [starRating, setStarRating] = useState(0);

  let heading1 = 'Fellowes Design & Experience Center';
  // let heading2 = 'Fellowes Design & Experience Center';
  let heading3 = 'This page will be updated once your session starts.';

  if(appointmentTime && appointmentTime !== '') {
    heading3 = `This page will be updated once your session starts on ${appointmentTime}`;
  }

  if (isRemoteAttendee) {
    heading1 = 'Fellowes Design & Experience Center';
    // heading2 = 'Stay updated with real time edits';

    if(appointmentTime) {
      heading3 = `This page will be updated once the session starts on ${appointmentTime}`;
    }
  }

  if(isLive) {
    heading1 = 'Stay updated with real time edits';
    heading3 = 'Below you will find all the content created with our Portray Experience';
  }


    return (
      <>
      <Header />

        <div className="mx-auto w-screen">
          
          <div className="relative w-full left-0 right-0 overflow-hidden bg-[#84908e] h-auto max-h-[600px] object-cover">
            <img alt="" className="hidden md:block w-full cover" src={Hero} />
            <img alt="" className="md:hidden w-full max-w-full " src={MobileHero} />
            {/* <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_rgba(0,0,0,0)_0%,_rgba(0,0,0,0.5)_100%)]" /> */}

            <div className="text-center left-0 right-0 px-[30px] md:px-0 text-white absolute bottom-[50px]">
              <div className="font-['Raleway'] font-semibold text-[42px] md:text-[32px] sm:text-[28px]">{heading1}</div>
              <div className="font-['Raleway'] font-normal text-[22px] md:text-[18px] sm:text-[16px]">{heading3}</div>
            </div>
          </div>
          <main className="relative ">
            {children}
          </main>
        </div>
  
        <div className="w-screen">
          <Share />
          <PreFooter />
          <Footer />
        </div>
      </>
    )
}

export default PageWrapper