import { useSearchParams } from 'react-router-dom';
import heroImage from "../assets/WaitingBackground.jpg";
import PageWrapper from '../components/PageWrapper.jsx';
import { ServiceHighlights } from '../components/ServiceHighlights';

function MainLanding({user, appointmentTime}) {

  const [searchParams, setSearchParams] = useSearchParams();
  let isRemoteAttendee = searchParams.get("remote");
  isRemoteAttendee = isRemoteAttendee === 'true';

  return (
    <PageWrapper isRemoteAttendee={isRemoteAttendee} appointmentTime={appointmentTime}>
      <div id="hero" className="md:py-0" style={{position: 'relative'}}>
        <img src={heroImage} className='md:relative absolute cover w-full h-full' />
        <div className="md:absolute relative inset-0 flex items-center justify-center pb-[50px] md:pb-0">
          <div className="flex flex-col items-center">
            {/* Spinner */}
            <svg 
              className="w-[160px] h-[160px] md:transform-none transform scale-[0.45] mb-[-5%] md:mb-0"
              viewBox="0 0 100 100" 
              xmlns="http://www.w3.org/2000/svg" 
              aria-label="Loading"
              role="img">
              <g>
                {[...Array(11)].map((_, i) => (
                  <circle
                    key={i}
                    r="6.5" // Radius: Chosen to ensure dots butt up against the 160 diameter boundary with ~6px spacing
                    fill="#e6e6e4" // Start with white
                    cx="50" // Center X: Positioned at the center of the SVG
                    cy="10" // Center Y: Positioned near the top to allow rotation around the center
                    transform={`rotate(${i * 32.727},50,50)`} // Rotation: 360° / 11 dots ≈ 32.727° per dot
                  >
                    <animate
                      attributeName="fill"
                      values="#e6e6e4;#6d6c6b;#e6e6e4" // Fade from white to grey and back to white
                      keyTimes="0;0.0909;1" // Timing: 1/11 of the duration (2s) for fade-in, rest for fade-out
                      keySplines="0.4 0 0.2 1; 0.4 0 0.2 1" // Easing: Smooth transitions between colors
                      dur="2s" // Duration: Total animation cycle time
                      begin={`${i * 0.1818}s`} // Stagger: 2s / 11 dots ≈ 0.1818s delay per dot
                      repeatCount="indefinite" // Loop: Repeat the animation indefinitely
                    />
                  </circle>
                ))}
              </g>
            </svg>

            {/* Text */}
            <div className="mt-0 md:mt-[40px] text-center">
              <p className="font-raleway font-bold text-[22px] text-[#6b6b6b]">
                Stay updated with real time edits
              </p>
              <p className="font-raleway font-medium text-[18px] mt-2 text-[#6b6b6b]">
                When your session begins, all the content created during your Portray Experience will appear here
              </p>
            </div>
          </div>
        </div>
      </div>
      <ServiceHighlights />
    </PageWrapper>
  )
}

export default MainLanding